section {
    background-color: #222831;
    color: #FFD369;
    border-radius: 25px;
    padding: 12px;
    min-height: 50vh;
}

.btn {
    background-color: #FFD369;
    color: #222831;
}

h3 {
    margin-top: 25px;
}

.btn:hover {
    background-color: #393E46;
}

.hero-section img {
    width: 80%;
}

.services-section img {
    width: 50%;
    margin-top: 25px;
}

.service-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.slide-in-right {
    -webkit-animation: slide-in-right 1.5s ease-out both;
    animation: slide-in-right 1.5s ease-out both;
}

@-webkit-keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-right {
    0% {
        -webkit-transform: translateX(1000px);
        transform: translateX(1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-left {
    -webkit-animation: slide-in-left 1.5s ease-out both;
    animation: slide-in-left 1.5s ease-out both;
}

@-webkit-keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes slide-in-left {
    0% {
        -webkit-transform: translateX(-1000px);
        transform: translateX(-1000px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
        opacity: 1;
    }
}
