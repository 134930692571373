.navbar-custom {
    background-color: #222831;
    padding: 6px;
}

.navbar-custom .nav-link {
    color: white !important;
    transition: 0.5s;
}

.navbar-custom .navbar-brand {
    color: #FFD369;
    font-weight: bold;
}

.navbar-custom .nav-link:hover {
    color: #FFD369 !important;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FFD369' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.custom-toggler:focus,
.custom-toggler:active,
.custom-toggler:focus-visible {
    outline: none !important;
    box-shadow: none !important;
}

