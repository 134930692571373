footer {
    background-color: #222831;
    color: #fff;
}

h5 {
    color: #FFD369;
}

footer a {
    color: #FFD369;
}

footer a:hover {
    color: #fff;
    text-decoration: underline;
}

span {
    color: #FFD369;
}